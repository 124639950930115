/* General settings */
body {
    font-family: 'Roboto', sans-serif;
    background-color: #ffffff;
    color: #333;
    margin: 0;
    padding: 20px;
}

h1, h2, h3 {
    color: #004080;
}

a:link {
color: blue;
}

a:visited {
color: blue;
}

a:hover {
color: green;
}

a:active {
color: red;
}

a:link, a:visited {
text-decoration: none;
}
  
.centered-container {
    margin: 0 auto;
    margin-left: 0px;
}

/* Search box */
.search-box {
    width: 300px;
    margin: 20px auto;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-box input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Tables */
.table {
    width: 100%;
    margin: 20px auto;
    border-collapse: collapse;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table th, .table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}

.table th {
    background-color: #f0f0f0;
    color: #004080;
}

.table tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Flex container for buttons and switch */
.controls-table-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

/* Container for buttons */
.buttons-container {
    display: flex;
    gap: 10px;
}

/* Button styling */
.button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #004080;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.button:hover {
    background-color: #003366;
    transform: translateY(-2px);
}

.button.selected {
    background-color: #0059b3;
    transform: translateY(-2px);
}

/* Smaller Toggle Switch */
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin-left: 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 20px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #004080;
}

input:checked + .slider:before {
    transform: translateX(20px);
}

.switch-container {
    font-size: 16px;
    font-weight: bold;
}

.title { 
    text-align: center;
    margin-top: 16px;
    margin-bottom: 12px;
    font-size: 24px;
}

.cue-container {
    position: fixed;
    bottom: 100px; /* Adjust this value based on the size of your chat icon */
    right: 20px;
    z-index: 1000;
  }
  
  .cue-text {
    background-color: rgba(255, 0, 0, 0.8); /* Red background for emphasis */
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    text-align: center;
  }
  
  .cue-arrow {
    position: absolute;
    bottom: -10px; /* Adjust this value to position the arrow correctly */
    right: 10px; /* Align the arrow to the right */
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(255, 0, 0, 0.8);
  }
  